import * as Yup from 'yup';

export const useCreateNewAddressSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required!')
      .max(14, 'First Name must be equal or less than 14 characters')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed'),
    lastName: Yup.string()
      .required('Last name is required!')
      .max(14, 'Last Name must be equal or less than 14 characters')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Special characters are not allowed'),
    email: Yup.string()
      .required('Email is required!')
      .email('Enter a valid email address!'),
  });
};
