import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { colors, fontsWeight } from "styles";
import { TextField } from "shared/components";
import { isError, isErrorMessage } from "shared/utils";
import { useFormik } from "formik";
import {
  useCreateNewAddressSchema,
  useSubmit,
} from "views/create-new-address/hooks";

const formHeaderTypographyStyles = {
  color: colors.kabul,
  textAlign: "center",
  fontSize: "14px",
  fontWeight: fontsWeight.fontMedium,
  lineHeight: "24px",
};

export const CreateNewAddress = () => {
  const { initialValues, onSubmit, isShippingLabelSent } = useSubmit();
  const validationSchema = useCreateNewAddressSchema();
  const { handleSubmit, getFieldProps, isSubmitting, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });

  const shippingFormElement = (
    <Grid container spacing="16px" component="form" onSubmit={handleSubmit}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          placeholder="First Name"
          variant="outlined"
          helperText={isErrorMessage("firstName", errors)}
          error={isError("firstName", errors, touched)}
          {...getFieldProps("firstName")}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          placeholder="Last Name"
          variant="outlined"
          helperText={isErrorMessage("lastName", errors)}
          error={isError("lastName", errors, touched)}
          {...getFieldProps("lastName")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Email"
          variant="outlined"
          helperText={isErrorMessage("email", errors)}
          error={isError("email", errors, touched)}
          {...getFieldProps("email")}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={false}
          loading={isSubmitting}
          sx={{
            background: colors.quicksand,
            borderRadius: 0,
            textAlign: "end",
            fontSize: "13px",
            fontFamily: "Post Grotesk Medium",
            fontWeight: fontsWeight.fontMedium,
            lineHeight: "24px",
            letterSpacing: "1px",
            height: "44px",
            padding: "16px 32px",
            minWidth: "263px",
            marginTop: "8px",
            "&:hover": {
              background: colors.quicksand,
            },
          }}
        >
          <span>Get Your Free Mailing Label</span>
        </LoadingButton>
      </Grid>
    </Grid>
  );

  const shippingLabelSuccessMessageElement = (
    <Box
      sx={{
        width: "993px",
        maxWidth: "100%",
        height: "356px",
        margin: "0 auto",
        backdropFilter: "blur(2px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        component="p"
        sx={{
          ...formHeaderTypographyStyles,
          fontWeight: fontsWeight.fontNormal,
          fontSize: "36px",
          fontFamily: "Abril Fatface, cursive",
          lineHeight: { xs: "1.2", sm: "24px" },
        }}
      >
        Thank you for recycling!
      </Typography>
      <Typography
        component="p"
        textTransform="uppercase"
        sx={{
          ...formHeaderTypographyStyles,
          marginTop: "30px",
        }}
      >
        your mailing label is in your inbox
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
        background: colors.alabaster,
      }}
    >
      <Card
        sx={{
          borderRadius: 0,
          width: "1200px",
          maxWidth: "100%",
          boxShadow: "none",
          padding: {
            xs: "12px",
            sm: "45px 44px",
            md: "80px 110px 128px 110px",
          },
        }}
      >
        <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
          <Box
            sx={{
              maxWidth: "100%",
              marginBottom: "70px",
            }}
          >
            <Box
              sx={{
                marginBottom: "32px",
              }}
            >
              <Typography
                component="p"
                sx={{
                  ...formHeaderTypographyStyles,
                }}
              >
                Boxes should be 6” x 6” x 3” or smaller. Envelopes should be 8”
                x 12” or smaller.
              </Typography>
              <Typography
                component="p"
                sx={{
                  ...formHeaderTypographyStyles,
                }}
              >
                Not sure if your beauty product is recyclable? Check Pact's
                guidelines.{" "}
                <Typography
                  component="a"
                  href="https://www.pactcollective.org/guidelines"
                  target="__blank"
                  sx={{
                    ...formHeaderTypographyStyles,
                  }}
                >
                  Check Pact's guidelines
                </Typography>
              </Typography>
            </Box>
            <Typography
              component="p"
              sx={{
                ...formHeaderTypographyStyles,
              }}
            >
              Your label will be emailed to you via{" "}
              <Typography
                component="a"
                href="mailto:mailback@pactcollective.org"
                sx={{
                  ...formHeaderTypographyStyles,
                }}
              >
                mailback@pactcollective.org
              </Typography>
              . Please contact them directly with questions.
            </Typography>
          </Box>
          {isShippingLabelSent
            ? shippingLabelSuccessMessageElement
            : shippingFormElement}
        </CardContent>
      </Card>
    </Box>
  );
};
