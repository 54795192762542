import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'routes';
import { Toaster } from 'react-hot-toast';
import './App.css';
import 'styles/fonts/post-grotest-medium/post-grotest-medium.css';

function App() {
  return (
    <BrowserRouter>
      <Toaster position="top-right" />
      <div>
        <Routes />
      </div>
    </BrowserRouter>
  );
}

export default App;
